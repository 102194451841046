.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  color: #000;
  display: inline-block;
  width: 1.7rem;
  line-height: 1.5rem;
  text-align: center;
  margin: 0.166rem;
}

.react-datepicker__header {
  background-color: #fff;
  border-bottom: 1px solid #ececec;
}

.react-datepicker {
  font-size: 0.8rem;
  background-color: #fff;
  color: #000;
  border: 1px solid #f1f1f1;
  box-shadow: 1px 1px 5px 2px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  display: inline-block;
  position: relative;
}
