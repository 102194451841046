.Footer {
  // color: #e4e8f0;
  background-color: #2e384d;
  flex: 0 0 auto;
  padding: 15px 0 15px;
  font-size: 14px;
  width: 100%;
  background-color: #fff;

  & ul {
    margin: 0;

    & li {
      padding-left: 0.3rem;
      padding-right: 0.3rem;
    }
  }

  & .logo {
    height: 3rem;
    margin: 1em 0em 2em 0em;
    margin-left: -0.8rem;
  }

  & .tagline {
    display: block;
    font-size: 0.9rem;
    font-weight: normal;
    color: var(--gray);
  }
  & .social-icons {
    margin-bottom: 0;
    & li {
      list-style-type: none;
      padding: 1.2rem;
      padding-top: 1.5rem;
      padding-bottom: 0;
      & svg {
        color: var(--gray);
      }
    }
  }

  & .disclaimer {
    color: var(--gray);
    padding: 0.5em;
    padding-bottom: 0;
    padding-top: 2em;
    text-align: justify;
  }
  & .caption {
    font-size: 0.9rem;
    font-style: italic;
    line-height: 1.5 !important;
  }

  & .privacy-policy {
    width: 100%;
    padding: 0 18px;
    text-align: right;
    & a {
      color: var(--gray) !important;
    }
  }

  @media (max-width: 576px) {
    & .logo {
      margin-left: -1.6rem;
      width: 11em;
    }
    & .tagline {
      margin-left: -0.85rem;
      font-size: 0.7rem;
    }
    & .col-md-7 {
      padding-left: 1.8rem !important;
      padding-right: 1.8rem !important;
    }
    & .social-icons {
      padding: 1em 0em 0em 0em;
      margin-left: -0.75rem;
    }
    & .privacy-policy {
      text-align: left;
      padding: 4em 0.3em;
      margin-bottom: 0 !important;
    }
    & .disclaimer {
      padding: 0 0.3em;
      text-align: justify;
    }
  }
}
