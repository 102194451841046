.filter-tags-inactive {
  background-color: lightgray;
}
.tags {
  cursor: pointer;
}

.profile {
  text-decoration: underline;
  font-size: 14px;
}
