/** The main css file includes custom css **/

/** custom
sass src/styles/index.scss src/index.css
poll for changes and automatically compiles
  sass --watch src/styles/index.scss:src/index.css
  #Allow you to include sass file directily into reactjs imports
  npm install node-sass --save
Install bootstrap sass
npm install bootstrap-sass
**/

@import "bootstrap";
@import "colors";
@import "typography";
@import "cards";
@import "calender";

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fafafa;

  .bookmark-icon {
    color: #ea7d64c2;
  }
}

.Main {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  .Footer {
    flex: 0 0 auto;
  }
  .MainContent,
  .Login,
  .ForgotPassword {
    flex: 1 0 auto;
  }
  .MainHeader {
    flex: 0 0 auto;
  }
}

.body-section {
  iframe,
  img {
    width: 100%;
  }
  img {
    height: auto !important;
  }
  & a {
    text-decoration: underline !important;
    text-decoration-color: #c2d3cd !important;
    text-decoration-thickness: 0.075em;
    text-underline-offset: 0.075em;
    &:hover {
      text-decoration-color: #5B7F73 !important;
    }
  }
}
