@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&family=Work+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

body {
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.3;
  color: var(--primary-dark);
}

h1 {
  font-size: 24px;
  font-weight: 600;
  line-height: 1.2;
  font-family: "Work Sans", sans-serif;
}

h2 {
  font-size: 23px;
  font-weight: 400;
  line-height: 1.3;
  font-family: "Work Sans", sans-serif;
}

h3 {
  font-size: 19px;
  font-weight: 400;
  line-height: 1.2;
  font-family: "Work Sans", sans-serif;
}

h4 {
  font-size: 15px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 1.2;
  font-family: "Work Sans", sans-serif;
}

h5 {
  font-size: 13px;
  font-weight: 400;
  line-height: 1.2;
  font-family: "Work Sans", sans-serif;
}

h6 {
  font-size: 11px;
  font-weight: 400;
  line-height: 1.2;
  font-family: "Work Sans", sans-serif;
}

p {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.3;
  font-family: "Open Sans", sans-serif;
}

.preformatted-text {
  white-space: pre-line;
}

.btn-success {
  color: white;
}

.btn-xs {
  padding: 0.15rem 0.5rem;
  font-size: 0.75rem;
  line-height: 1.3;
  border-radius: 0.2rem;
}

.Content {
  font-size: 13px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 1.5;

  img,
  iframe,
  embed,
  video,
  object {
    max-width: 100%;
    height: auto !important;
  }

  h1 {
    font-size: 23px;
    font-weight: bold;
    letter-spacing: normal;
    line-height: 1.3;
  }
  h2 {
    font-size: 21px;
    font-weight: bold;
    letter-spacing: normal;
    line-height: 1.3;
  }
  h3 {
    font-size: 17px;
    font-weight: bold;
    letter-spacing: normal;
    line-height: 1.3;
  }
  h4 {
    font-size: 15px;
    font-weight: bold;
    letter-spacing: normal;
    line-height: 1.3;
  }
  h5 {
    font-size: 14px;
    letter-spacing: normal;
    line-height: 1.3;
  }
  h6 {
    font-size: 13px;
    letter-spacing: normal;
    line-height: 1.3;
  }
  p {
    font-size: 13px;
    font-weight: 400;
    letter-spacing: normal;
    line-height: 1.5;
  }
  a {
    font-size: inherit;
    font-weight: 400;
    letter-spacing: normal;
    line-height: 1.5;
    color: #3c4dc8;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    a {
      font-size: inherit;
      font-weight: 400;
      letter-spacing: normal;
      line-height: 1.5;
      color: #3c4dc8;
    }
  }
}
