.home-tabs {
  & .home-section {
    width: 45%;
    border-radius: 5em;
    font-weight: bold;
    margin-left: 0.05em;
    & .nav-item {
      margin-left: 0.2em;
      margin-right: 0.2em;
    }
    & .nav-link {
      background-color: rgba(234, 125, 100, 0.15);
      border-radius: 5em;
    }
    & .nav-link.active {
      background-color: #ea7d64b5;
      width: 100%;
      font-weight: bold;
      border-radius: 5em;
    }
  }
  & a.nav-item {
    min-width: -webkit-fit-content;
  }
}

@media (max-width: 48em) {
  .home-tabs .home-section {
    width: 100%;
  }
  .mobile {
    display: block;
  }
  .desktop {
    display: none;
  }
}
