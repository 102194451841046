.recommended {
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #fafafa;
    border: transparent;
    border-bottom: 2px solid #ea7d64;
  }
  .nav-tabs {
    a:first-child {
      // margin-left: 0;
      // padding-left: 0;
    }
  }
  h2 {
    display: block;
    width: fit-content;
  }
  .recommended-tabs {
    overflow-x: auto;
    overflow-y: none;
  }
}
