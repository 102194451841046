.favorites {
  .card-img,
  .card-img-top,
  .card-img-bottom {
    max-height: 185.5px;
  }

  .centered-card-title {
    height: 50px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .read-more {
    margin-left: -1.5rem !important;
    text-decoration: underline;
    text-transform: capitalize;
    font-size: 13px;
    margin-top: 5px;
    color: #2e384d;
  }
  & .content-title {
    display: -webkit-box;
    text-overflow: ellipsis;
    width: 100%;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

@media (max-width: 767.98px) {
  .favorites {
    .card {
      flex-direction: row;
    }

    .card-img,
    .card-img-top,
    .card-img-bottom {
      width: 175px;
      height: fit-content;

      border-radius: calc(0.25rem - 1px);
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    .card-body {
      padding: 0.5rem !important;
    }
  }
}

@media only screen and (min-width: 768px) {
  .favorites {
    .card-img,
    .card-img-top,
    .card-img-bottom {
      max-height: 185.5px;
    }
  }
}

@media only screen and (min-width: 992px) {
  .favorites {
    .card-img,
    .card-img-top,
    .card-img-bottom {
      max-height: 118px;
    }
  }
}

@media only screen and (min-width: 1200px) {
  .favorites {
    .card-img,
    .card-img-top,
    .card-img-bottom {
      max-height: 143.4px;
    }
  }
}
