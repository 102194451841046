.check-in {
  & .mood {
    padding: 0px 0.25em 0 0.25em;
    &:hover {
      cursor: pointer;
    }
    &.selected {
      background-color: rgba(234, 125, 100, 0.5);
    }
  }
}

@media (max-width: 767.98px) {
  .check-in {
    & .mood {
      padding: 0px 0.15em 0 0.15em;
    }
  }
}
