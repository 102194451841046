$body-background: #f6f6f6;

$primary-default: #ffffff;
$primary-dark: #2e384d;
$primary-neutral: #8798ad;
$primary-accent: #e4e8f0;
$primary-light: #f5f6fb;

$secondary-neutral: #5a54fa;
$secondary-accent: #d5d4ff;

$tertiary-neutral: #8c63ea;
$tertiary-success: #50d2c2;
$tertiary-emphasis: #d667cd;
$tertiary-warning: #fcab53;
$tertiary-info: #00b9ff;
$tertiary-danger: #ff3366;

$logo-color: #5861a6;

:root {
  --primary-color: #52448a;
  --primary-dark: #2e384d;
  --secondary-color: #5a54fa;
  --tertiary-color: #8c63ea;
  --background-color: #f6f6f6;
  --link-color: #52448a;
}
