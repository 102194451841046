.ContentSummaryList {
  .card-img,
  .card-img-top,
  .card-img-bottom {
    max-height: 185.5px;
  }

  & .carousel-item {
    & .card-img,
    .card-img-top,
    .card-img-bottom {
      max-height: 185.5px;
    }
  }

  .centered-card-title {
    height: 50px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .read-more {
    margin-left: -1.5rem !important;
    text-decoration: underline;
    text-transform: capitalize;
    font-size: 13px;
    margin-top: 5px;
    color: #2e384d;
  }
  & .content-title {
    display: -webkit-box;
    text-overflow: ellipsis;
    width: 100%;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

@media (max-width: 767.98px) {
  .ContentSummaryList {
    .card {
      flex-direction: row;
    }

    .card-img,
    .card-img-top,
    .card-img-bottom {
      width: 175px;
      height: auto;

      border-radius: calc(0.25rem - 1px);
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    .card-body {
      padding: 0.5rem !important;
    }
    & .carousel-indicators {
      display: block;
      text-align: center !important;
      top: 100%;
      margin-top: 1em;
      & li {
        background-color: #ea7d64b5;
        padding: 0.2em;
        border-radius: 2em;
        display: inline-block;
        width: 0.5em;
        height: 0.5em;
        border-radius: 1em;
        border-top: none;
        border-bottom: none;
      }
    }
    & .carousel-control-prev , .carousel-control-next {
      display: none;
    }
  }
}

@media only screen and (min-width: 768px) {
  .ContentSummaryList {
    .card-img,
    .card-img-top,
    .card-img-bottom {
      max-height: 185.5px;
    }
    & .carousel-indicators {
      display: block;
      text-align: center !important;
      top: 100%;
      margin-top: 1em;
      & li {
        background-color: #ea7d64b5;
        padding: 0.2em;
        border-radius: 2em;
        display: inline-block;
        width: 0.5em;
        height: 0.5em;
        border-top: none;
        border-bottom: none;
      }
    }
    & .carousel-control-prev , .carousel-control-next {
      display: none;
    }
  }
}

@media only screen and (min-width: 992px) {
  .ContentSummaryList {
    .card-img,
    .card-img-top,
    .card-img-bottom {
      max-height: 118px;
    }
    // & .carousel-indicators {
    //   // display: none;
    // }
    & .carousel-control-prev, .carousel-control-next {
      display: block;
      top: 50%;
      &-icon {
        width: 2em;
        height: 2em;
      }
    }
  
    & .carousel-control-prev span {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23ea7d64b5' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
    }
    & .carousel-control-next span {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23ea7d64b5' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
    }
  }
}

@media only screen and (min-width: 1200px) {
  .ContentSummaryList {
    .card-img,
    .card-img-top,
    .card-img-bottom {
      max-height: 143.4px;
    }
  }
}
