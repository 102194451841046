.card-primary {
  background: $primary;
  color: #feeed2bf;
  h2,
  p {
    color: #feeed2bf;
  }
}

.card {
  p {
    a {
      color: #3c4dc8;
    }
  }
}
