.Main {
  .MainHeader {
    background-color: #fff !important;
    .navbar-toggler {
      padding: 0.25rem 0rem;
      border: 1px solid transparent;
    }
  }
}

.navbar-brand {
  font-family: "Work Sans", sans-serif;
  font-weight: 600;
  span {
    border-bottom: 3px solid #4f7f71;
  }
}

.nav-collapse-icons {
  display: none !important;
  flex-direction: row;
}

@media only screen and (min-width: 360px) and (max-width: 1024px) {
  .nav-collapse-icons {
    display: flex !important;
  }
  .nav-desktop-icons {
    display: none !important;
  }
}

@media only screen and (min-width: 992px) {
  .nav-desktop-icons {
    display: flex !important;
  }

  .nav-collapse-icons {
    display: none !important;
  }
}

.MainHeader {
  .dropdown-menu {
    right: 0;
    left: unset;
    font-size: inherit;
  }
}
